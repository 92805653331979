// Override default variables before the import
$primary: #1e4b5a;
$font-family-sans-serif: "Noto Sans Display";
$headings-font-family: "Tilt Neon";
$headings-font-weight: 500 !default;
// $font-size-base: 1rem;
$input-btn-font-family: "Tilt Neon";
$input-font-family: "Noto Sans Display";
$font-family-base: $font-family-sans-serif !default;
$border-radius: 1rem !default;
$form-label-color: #414141 !default;
$light-yellow: #f7ee7d;

$spacer: 1.2rem;

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1500px,
);

@import "~bootstrap/scss/bootstrap.scss";

.input-webkitfix {
  user-select: text;
  -webkit-user-select: text;
}

.links {
  text-decoration: none;
}

.zigzag {
  height: 25px;
  // background: linear-gradient(135deg, white 35%, transparent 25%) -25px 0,
  //   linear-gradient(225deg, white 35%, transparent 25%) -25px 0,
  //   linear-gradient(315deg, white 35%, transparent 25%),
  //   linear-gradient(45deg, white 35%, transparent 25%),
  //   radial-gradient(#212121 20%, transparent 20%),
  //   radial-gradient(#fafafa 20%, transparent 20%);
  background-image: radial-gradient(white 20%, transparent 20%),
    radial-gradient(#fafafa 20%, transparent 20%);
  background-size: 25px 25px;
  background-color: $primary;
}

.rbt-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}

.spin {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body,
#root {
  height: 100%;
  background-color: #f2f2f2;
}

.btn-half-red {
  @include button-variant($light, $light);
  background: linear-gradient(
    -45deg,
    lighten($red, 30%) 0%,
    lighten($red, 30%) 50%,
    $light 50%,
    $light 100%
  );
}
.btn-light-yellow {
  @include button-variant(lighten($yellow, 30%), lighten($yellow, 30%));
}
.btn-half-yellow {
  @include button-variant($light, $light);
  background: linear-gradient(
    -45deg,
    lighten($yellow, 30%) 0%,
    lighten($yellow, 30%) 50%,
    $light 50%,
    $light 100%
  );
}

.btn-light-red {
  @include button-variant(lighten($red, 30%), lighten($red, 30%));
}
.btn-light-green {
  @include button-variant(lighten($green, 30%), lighten($green, 30%));
}

td {
  white-space: pre;
}

.whitespace-pre {
  white-space: pre;
}

.rbt-loader {
  display: none;
}
